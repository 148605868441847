import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d5614e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "selected_box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProTable = _resolveComponent("ProTable")!
  const _component_ArrowRight = _resolveComponent("ArrowRight")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_DeleteFilled = _resolveComponent("DeleteFilled")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_ProTable, {
        rowKey: $props.rowKey,
        tableId: $props.tableController.tableId,
        columns: $props.tableController.columns,
        tableData: $props.tableController.tableData,
        loading: $props.tableController.loading,
        total: $props.tableController.total,
        pageState: $props.tableController.pageState,
        onPageChange: $props.tableController.onPageChange,
        isSelection: true,
        selectedRows: $props.tableController.selectedRows,
        onSelect: $props.tableController.onSelect
      }, null, 8, ["rowKey", "tableId", "columns", "tableData", "loading", "total", "pageState", "onPageChange", "selectedRows", "onSelect"])
    ]),
    _createVNode(_component_el_icon, { style: {"margin-top":"180px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowRight)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, "共" + _toDisplayString($props.tableController.selectedRows.length) + "项", 1),
      _createVNode(_component_el_table, {
        data: $props.tableController.selectedRows,
        "row-key": $props.rowKey,
        border: true,
        size: "small",
        "max-height": "550px"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selectedColumns ?? [], (column, index) => {
            return (_openBlock(), _createBlock(_component_el_table_column, {
              key: index,
              property: column.prop,
              label: column.label,
              align: "center",
              width: column.width || false
            }, null, 8, ["property", "label", "width"]))
          }), 128)),
          _createVNode(_component_el_table_column, {
            label: "操作",
            align: "center",
            width: 80
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_icon, {
                class: "delete_selected_item",
                onClick: ($event: any) => ($setup.handleDeleteItem(scope))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DeleteFilled)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "row-key"])
    ])
  ]))
}