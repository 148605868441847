
import ProTable from '@/components/ProTable/index.vue'
import { DeleteFilled, ArrowRight } from '@element-plus/icons-vue'

export default {
  props: ['rowKey', 'tableController', 'selectedColumns'],
  setup(props) {
    function handleDeleteItem(e) {
      const item = e.row
      // @ts-ignore
      props.tableController?.onSelect({
        rows: [item],
        selected: false,
      })
    }
    return {
      handleDeleteItem,
    }
  },
  components: { DeleteFilled, ArrowRight, ProTable },
}
