<template>
  <StyledModal :visible="visible" :title="'关联医生'" @close="close" @submit="submit" :submitLoading="submitLoading" width="1210px">
    <div style="margin-bottom: -20px">
      <SearchForm ref="searchFormRef" :formModel="searchController.formModel" @search="tableController.search" :searchLoading="tableController.loading">
        <template #form>
          <el-form-item prop="doctorName">
            <el-input placeholder="医生名称" v-model="searchController.formModel.doctorName" clearable></el-input>
          </el-form-item>
          <el-form-item prop="phoneNumber">
            <el-input placeholder="医生手机" v-model="searchController.formModel.phoneNumber" clearable></el-input>
          </el-form-item>
          <el-form-item prop="departCode">
            <FetchSelect
              placeholder="科室"
              v-model="searchController.formModel.departCode"
              api="/web/system/depart/getDepartList"
              :postData="{
                page: 1,
                rows: 900,
              }"
              valueKey="departCode"
              textKey="departName"
              :isPaging="true"
            />
          </el-form-item>
        </template>
      </SearchForm>
    </div>
    <TransferTable :rowKey="'doctorCode'" :tableController="tableController" :selectedColumns="selectedColumns" />
  </StyledModal>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { cloneDeep } from 'lodash'
import StyledModal from '@/components/modal/StyledModal'
import ImgUpload from '@/components/ImgUpload'
import FetchSelect from '@/components/FetchSelect/index.vue'
import request from '@/utils/request'
import TransferTable from '@/components/TransferTable/index.vue'
import useTableController from '@/hooks/useTableController'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import SearchForm from '@/components/SearchForm/index.vue'
import { simpleConfirm } from '@/utils/confirm'
import { ElMessage } from 'element-plus'

export default {
  setup(_, ctx) {
    const visible = ref(false)
    const submitLoading = ref(false)

    const record = reactive({ v: null })

    function open(recordParam) {
      visible.value = true
      if (recordParam) {
        record.v = cloneDeep(recordParam)

        tableController.search()
        getAssociatedlDoctorList()
      }
    }

    function close() {
      visible.value = false
      record.v = null
    }

    // searchForm
    const searchController = useSearchFormCtrller({
      form: {
        doctorName: '',
        phoneNumber: '',
        departCode: '',
      },
    })

    // 左侧 医生列表
    const columns = [
      { label: '姓名', prop: 'doctorName', width: 150 },
      { label: '手机号', prop: 'phoneNumber', width: 150 },
      { label: '科室', prop: 'departName', width: 'auto' },
    ]
    const selectedColumns = [
      { label: '姓名', prop: 'doctorName', width: 120 },
      { label: '手机号', prop: 'phoneNumber', width: 130 },
    ]
    const tableController = useTableController({
      tableId: 'depart_center_doctor_modal_table',
      rowKey: 'doctorCode',
      columns,
      ajax: ({ page, pageSize }) => {
        const postData = {
          page,
          rows: pageSize,
          departCenterCode: record.v?.departCenterCode,
          ...searchController.formModel,
        }
        return request({
          url: '/web/system/departcenter/getDoctorList',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
      isSelection: true,
      dependence: record,
      getIsReady: (recordValue) => recordValue?.departCenterCode,
    })

    // 获取已关联医生列表
    function getAssociatedlDoctorList() {
      request({
        url: '/web/system/departcenter/getDepartCenterDoctorList',
        data: {
          page: 1,
          rows: 900,
          departCenterCode: record.v?.departCenterCode,
        },
      }).then((data) => {
        const list = data?.data ?? []
        tableController.setSelectedRows(list)
      })
    }

    // 提交
    async function submit() {
      await simpleConfirm('确定修改关联医生？')

      let doctorCodeStr = ''
      if (tableController.selectedKeys?.length > 0) {
        doctorCodeStr = tableController.selectedKeys.join(',')
      }

      submitLoading.value = true
      request({
        url: '/web/system/departcenter/replaceDoctorRightDataList',
        data: {
          departCenterCode: record.v?.departCenterCode,
          doctorCodeStr,
        },
      })
        .finally(() => {
          submitLoading.value = false
        })
        .then(() => {
          ElMessage.success('操作成功')
          close()
        })
    }

    return {
      visible,
      submitLoading,
      open,
      close,
      submit,
      tableController,
      selectedColumns,
      searchController,
    }
  },
  components: { StyledModal, FetchSelect, ImgUpload, TransferTable, SearchForm },
}
</script>

<style lang="less" scoped>
.form_box {
  width: 90%;
}
</style>
